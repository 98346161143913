// extracted by mini-css-extract-plugin
export var count = "bestInsuranceTable-module--count--4cca5";
export var singleInsuranceBbb = "bestInsuranceTable-module--singleInsuranceBbb--4224f";
export var singleInsuranceButton = "bestInsuranceTable-module--singleInsuranceButton--378d1";
export var singleInsuranceMoneyBack = "bestInsuranceTable-module--singleInsuranceMoneyBack--8e7f9";
export var singleInsuranceProvider = "bestInsuranceTable-module--singleInsuranceProvider--5cd08";
export var singleInsuranceProviderTooltip = "bestInsuranceTable-module--singleInsuranceProviderTooltip--ad5c6";
export var singleInsuranceScore = "bestInsuranceTable-module--singleInsuranceScore--2ab3e";
export var singleInsuranceTable = "bestInsuranceTable-module--singleInsuranceTable--84959";
export var singleInsuranceTitle = "bestInsuranceTable-module--singleInsuranceTitle--4a947";
export var singleInsuranceWaitingPeriod = "bestInsuranceTable-module--singleInsuranceWaitingPeriod--15b52";
export var tooltip = "bestInsuranceTable-module--tooltip--68754";